const menu = [
  {
    header: 'Central de informações',
    profile: [1, 3, 2],
  },
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    profile: [1, 2, 3],
  },
  {
    title: 'Relatórios',
    profile: [1, 2, 3],
    icon: 'TrendingUpIcon',
    children: [
      {
        title: 'Tabelas de Monitoramento',
        route: 'monitoring-table',
        icon: 'FileIcon',
        profile: [1, 2, 3],
      },
    ],
  },
  {
    header: 'Cadastros',
    profile: [1],
  },
  {
    title: 'Empresa',
    route: 'empresa',
    icon: 'BriefcaseIcon',
    profile: [1],
  },
  {
    title: 'Dispositivos',
    route: 'dispositivos',
    icon: 'HardDriveIcon',
    profile: [1],
  },
  {
    header: 'Adminstração do Sistema',
    profile: [1],
  },
  {
    title: 'Usuários',
    route: 'usuarios',
    icon: 'UserIcon',
    profile: [1],
  },
  {
    title: 'Importar Arquivo',
    route: 'file-upload',
    icon: 'FileIcon',
    profile: [1, 2],
  },
]

export default menu
